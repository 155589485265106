import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { contentFocusIndicatorFocusedTargetClass } from '../../../../shared/constants.platform';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import { globalState } from '../../../../shared/foreground/models';
import {
  slowDownYtPlaybackRate,
  speedUpYtPlaybackRate,
  toggleYTAutoScroll,
} from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/youtubePlayer';
import {
  setSeekYtPlayerTo,
  setYtVideoPlaying,
  toggleYtVideoPlaying,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/youtubePlayer';
import { useSeekYouTubeVideoToInitialProgress } from '../../../../shared/foreground/utils/useSeekYouTubeVideoToInitialProgress';
import { useUpdateVideoScrollPosition } from '../../../../shared/foreground/utils/useUpdateVideoScrollPosition';
import {
  getCurrentTimeIndex,
  getElementFromTime,
  getStartTimeFromEl,
  getYoutubeIdFromUrl,
} from '../../../../shared/foreground/utils/youtubeHelpers';
import type { DocumentId, Highlight } from '../../../../shared/types';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { DefaultYouTubePlaybackRate } from '../../../../shared/utils/youtubeConstants';
import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import convertVhToPxSafe from '../../utils/convertVhToPxSafe';
import { useShortcutsMap } from '../../utils/shortcuts';
import { baseShortcuts } from '../../utils/shortcuts/defaultsByLayout';
import { FailedToRenderHighlightsBanner } from '../FailedToRenderHighlightsBanner';
import Spinner from '../Spinner';
import styles from './EmbeddedYoutubeDocument.module.css';

function EmbeddedYoutubeDocument({
  docId,
  url,
  transcriptHtml,
  scrollDepth,
  failedExtensionOrYouTubeHighlightsHighlightIds,
}: {
  docId: DocumentId;
  url: string;
  transcriptHtml: string;
  scrollDepth?: number | null;
  failedExtensionOrYouTubeHighlightsHighlightIds?: Highlight['id'][];
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [playedSeconds, setPlayedSeconds] = useState<number | undefined>(undefined);
  const [startTimes, setStartTimes] = useState<number[] | null>(null);
  const seekTo = globalState(useCallback((state) => state.youtube.seekTo, []));
  const playbackRate = globalState(
    useCallback((state) => state.client.youtube.playbackRate || DefaultYouTubePlaybackRate, []),
  );
  const currentSelectedStartTime = useRef<number | null>(null);
  const videoId = useMemo(() => getYoutubeIdFromUrl(url), [url]);
  const playerRef = useRef<ReactPlayer>(null);
  const autoScrollEnabled = globalState(useCallback((state) => state.client.youtube.autoScroll, []));
  const ytIsPlaying = globalState(useCallback((state) => state.youtube.isPlaying, []));
  const isVideoHeaderShown = globalState(useCallback((state) => state.isVideoHeaderShown, []));
  const shortcutsMap = useShortcutsMap();
  const [videoDuration, setVideoDuration] = useState<number | undefined>(undefined);

  const seekToCallback = playerRef.current?.seekTo
    ? (seconds: number) => playerRef.current?.seekTo(seconds)
    : undefined;

  useSeekYouTubeVideoToInitialProgress({
    scrollDepth,
    videoDuration,
    isPlaying: ytIsPlaying,
    seekTo: seekToCallback,
  });

  useUpdateVideoScrollPosition({
    docId,
    videoDuration,
    playedSeconds,
  });

  const seekToFocusedParagraph = useCallback(() => {
    const currentFocusedEl = document.querySelector(
      `.${contentFocusIndicatorFocusedTargetClass} span[data-rw-start]`,
    ) as HTMLSpanElement | null;

    if (currentFocusedEl) {
      const newSeekTo = getStartTimeFromEl(currentFocusedEl);
      if (newSeekTo) {
        setSeekYtPlayerTo(newSeekTo);
      }
    }
  }, []);

  useEffect(() => {
    eventEmitter.on('yt-seek-to-focused-paragraph', seekToFocusedParagraph);

    return () => {
      eventEmitter.off('yt-seek-to-focused-paragraph', seekToFocusedParagraph);
    };
  }, [seekToFocusedParagraph]);

  useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.CmdOrCtrlAndEnter], seekToFocusedParagraph, {
    description: 'Seek to current focused transcript',
  });

  useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.SpeedUpPlayback], speedUpYtPlaybackRate, {
    description: 'Speed up YouTube playback rate',
  });

  useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.SlowDownPlayBack], slowDownYtPlaybackRate, {
    description: 'Slow down YouTube playback rate',
  });

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.ToggleYtAutoScroll],
    useCallback(() => toggleYTAutoScroll({ userInteraction: 'keypress' }), []),
    {
      description: 'Toggle YouTube auto scroll',
    },
  );

  useKeyboardShortcutPreventDefault(
    useMemo(() => [baseShortcuts.Space], []),
    useCallback(() => toggleYtVideoPlaying(), []),
    { description: 'Toggle video playing' },
  );

  const secondsToChange = 15;

  const skipForward = useCallback(() => {
    setSeekYtPlayerTo((playedSeconds ?? 0) + secondsToChange);
  }, [playedSeconds]);

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.SkipForward],
    useCallback(() => skipForward(), [skipForward]),
    { description: 'Skip forward in YouTube video' },
  );

  useEffect(() => {
    eventEmitter.on('yt-skip-forward', skipForward);

    return () => {
      eventEmitter.off('yt-skip-forward', skipForward);
    };
  }, [skipForward]);

  const skipBackwards = useCallback(() => {
    const newSeekTo = (playedSeconds ?? 0) - secondsToChange;
    if (newSeekTo > 0) {
      setSeekYtPlayerTo(newSeekTo);
    } else {
      setSeekYtPlayerTo(0);
    }
  }, [playedSeconds]);

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.SkipBackwards],
    useCallback(() => skipBackwards(), [skipBackwards]),
    { description: 'Skip backwards in YouTube video' },
  );

  useEffect(() => {
    eventEmitter.on('yt-skip-backwards', skipBackwards);

    return () => {
      eventEmitter.off('yt-skip-backwards', skipBackwards);
    };
  }, [skipBackwards]);

  // Seek to time if seekTo is set and then reset it
  useEffect(() => {
    if (!seekTo || !playerRef.current) {
      return;
    }

    playerRef.current.seekTo(seekTo);
    setYtVideoPlaying(true);
    setSeekYtPlayerTo(null);
  }, [seekTo]);

  // Get all the start times from transcript
  useEffect(() => {
    if (startTimes || !transcriptHtml) {
      return;
    }

    const doc = new DOMParser().parseFromString(transcriptHtml, 'text/html');

    const times = Array.from(doc.querySelectorAll<HTMLElement>('span[data-rw-start]'), (el) =>
      Number(el.dataset.rwStart),
    );
    setStartTimes(times);
  }, [startTimes, transcriptHtml]);

  // Add click listener to transcript elements to seek to time
  useEffect(() => {
    const onTranscriptClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const seekTo = getStartTimeFromEl(target);

      if (seekTo) {
        setSeekYtPlayerTo(seekTo);
      }
    };

    document.addEventListener('click', onTranscriptClick);

    return () => document.removeEventListener('click', onTranscriptClick);
  }, []);

  // Remove previous highlight and add new to current transcript
  useEffect(() => {
    if (!playedSeconds || !startTimes) {
      return;
    }

    // Remove previous highlight
    if (currentSelectedStartTime && currentSelectedStartTime.current) {
      const currentTranscript = getElementFromTime(currentSelectedStartTime.current);
      if (currentTranscript) {
        currentTranscript.classList.remove(styles.teleprompter);
      }
    }

    // Add new highlight
    const currentStartTimeIndex = getCurrentTimeIndex({ times: startTimes, currentTime: playedSeconds });
    const currentStartTime = currentStartTimeIndex !== null && startTimes[currentStartTimeIndex];

    if (currentStartTime) {
      const el = getElementFromTime(currentStartTime);

      if (!el) {
        return;
      }

      el.classList.add(styles.teleprompter);

      currentSelectedStartTime.current = currentStartTime;

      if (autoScrollEnabled) {
        const paddingTop = convertVhToPxSafe(50) + 150;
        const contentWrapper = document.getElementById('document-reader-root');
        contentWrapper?.scroll({ top: el.offsetTop - paddingTop, behavior: 'smooth' });
        eventEmitter.emit('update-content-focus-indicator-target', { target: el });
      }
    }
  }, [playedSeconds, startTimes, autoScrollEnabled]);

  const [isFailedToRenderHighlightsBannerVisible, setIsFailedToRenderHighlightsBannerVisible] =
    useState(false);

  return (
    <div
      className={`${styles.ytPlayerContainer} ${isVideoHeaderShown ? '' : styles.isVideoHeaderHidden} ${isFailedToRenderHighlightsBannerVisible ? styles.isFailedToRenderHighlightsBannerVisible : ''}`}
    >
      <ReactPlayer
        ref={playerRef}
        url={`https://www.youtube.com/embed/${videoId}`}
        controls
        style={{ height: '50vh', display: isLoading ? 'none' : '' }}
        playing={ytIsPlaying}
        playbackRate={playbackRate}
        onProgress={(p) => setPlayedSeconds(p.playedSeconds)}
        onPause={() => setYtVideoPlaying(false)}
        onPlay={() => setYtVideoPlaying(true)}
        onDuration={setVideoDuration}
        onReady={() => {
          setIsLoading(false);
          eventEmitter.emit('refocus-content-focus-indicator');
        }}
      />

      {!isLoading && failedExtensionOrYouTubeHighlightsHighlightIds && (
        <FailedToRenderHighlightsBanner
          highlightIds={failedExtensionOrYouTubeHighlightsHighlightIds}
          onVisibleChange={setIsFailedToRenderHighlightsBannerVisible}
          className={styles.failedToRenderHighlightsBanner}
          text={`You made some highlight${failedExtensionOrYouTubeHighlightsHighlightIds.length === 1 ? '' : 's'} before this transcript was enhanced and can't be shown overlaid.`}
        />
      )}

      {isLoading && (
        <div className={styles.loadingWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default memo(EmbeddedYoutubeDocument);
